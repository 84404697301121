import {
  faCog,
  faMessage,
  faProjectDiagram,
  faSignOutAlt,
  faTasks,
  faTimes,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import store from "../../redux";
import { setUserName } from "../../redux/ChatStore";
import animeBoy from "../../svg/animeboy.svg";

const drawerWidth = 240;
const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#171717",
    color: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  profileIcon: {
    fontSize: "2rem",
    marginBottom: theme.spacing(1),
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#333",
    },
  },
  listItemActive: {
    backgroundColor: "#444",
    "&:hover": {
      backgroundColor: "#555",
    },
  },
  icon: {
    color: "#bbb",
  },
  mutedText: {
    color: "red",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1),
  },
}));

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: Function;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("user");
    store.dispatch(setUserName(""));
    navigate("/login");
    setSidebarOpen(false);
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [isMute, setIsMute] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setIsAdmin(!!user.isAdmin);
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state: any = store.getState();
      setIsMute(state.isMute);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleClose = () => {
    if (isMobile) setSidebarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        className={classes.drawer}
        variant={isMobile ? "temporary" : "permanent"}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        {isMobile && (
          <div className={classes.closeButton}>
            <FontAwesomeIcon
              onClick={() => setSidebarOpen(false)}
              icon={faTimes}
            />
          </div>
        )}
        <List onClick={handleClose}>
          <ListItem button component={Link} to="/chat">
            <div className={classes.profile}>
              <img src={animeBoy} alt="profile" width={"80px"} />
              <Typography variant="subtitle1" align="center">
                {localStorage.getItem("token") === "guest"
                  ? "daniel wendemeneh"
                  : "Personal Assistant"}
                {isMute && <span className={classes.mutedText}> (Muted)</span>}
              </Typography>
            </div>
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/chat"
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faMessage} />
            </ListItemIcon>
            <ListItemText primary="Pa Chat" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/profile"
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faUser} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/projects"
            className={classes.listItem}
          >
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faProjectDiagram} />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
          {isAdmin && (
            <>
              <ListItem
                button
                component={Link}
                to="/tasks"
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <FontAwesomeIcon icon={faTasks} />
                </ListItemIcon>
                <ListItemText primary="Tasks" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/managePasswords"
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <FontAwesomeIcon icon={faUnlockAlt} />
                </ListItemIcon>
                <ListItemText primary="Passwords" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/account"
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <FontAwesomeIcon icon={faUser} />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/settings"
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <FontAwesomeIcon icon={faCog} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </>
          )}
          <ListItem button onClick={handleLogout} className={classes.listItem}>
            <ListItemIcon className={classes.icon}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
