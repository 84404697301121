import { useEffect, useRef } from "react";
import {
  addMessage,
  setChannel,
  setMessages,
  setRefreshed,
} from "../redux/ChatStore";
import store from "../redux";

export interface Message {
  content: string;
  channelId: string;
  sender: string;
  event: string;
}

const WebSocketClient = () => {
  const reconnectAttempts = useRef(0);
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
  const messageQueue = useRef<Message[]>([]);
  const isManuallyClosed = useRef(false); // Track manual closure
  const socketRef = useRef<WebSocket | null>(null);

  const connectSocket = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log("✅ WebSocket already connected, skipping reconnection.");
      return;
    }

    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const user = localStorage.getItem("user");
    if (!user) {
      console.error("No user found in localStorage");
      return;
    }

    const fullUrl =
      process.env.NODE_ENV === "development"
        ? `${protocol}://localhost:8081/?user=${encodeURIComponent(user)}`
        : `${protocol}://${
            process.env.REACT_APP_Domain || window.location.hostname
          }/?user=${encodeURIComponent(user)}`;

    console.log("Connecting to WebSocket:", fullUrl);
    const socket = new WebSocket(fullUrl);
    socketRef.current = socket;
    isManuallyClosed.current = false; // Reset manual closure flag

    socket.onopen = () => {
      console.log("✅ WebSocket connected");
      reconnectAttempts.current = 0; // Reset reconnection attempts

      // Send any queued messages
      while (messageQueue.current.length > 0) {
        const queuedMessage = messageQueue.current.shift();
        if (queuedMessage) {
          socket.send(JSON.stringify(queuedMessage));
        }
      }
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);

        if (message.event === "MESSAGE_HISTORY") {
          store.dispatch(setRefreshed(true));
          store.dispatch(setMessages(message.messages));
        } else if (
          message.event === "channelDeleted" ||
          message.event === "channelArchived"
        ) {
          store.dispatch(setRefreshed(true));
          store.dispatch(setMessages([]));
        } else if (message.content && message.channelId && message.sender) {
          const activeChannel = store.getState().channel;

          if (message.channelId === activeChannel) {
            store.dispatch(addMessage(message));
          } else {
            console.log(
              "📌 Ignoring message from inactive channel:",
              message.channelId
            );
          }
        } else {
          console.warn("⚠️ Invalid message structure:", message);
        }
      } catch (error) {
        console.error("❌ Failed to parse WebSocket message:", error);
      }
    };

    socket.onclose = (event) => {
      console.warn("⚠️ WebSocket closed:", event.reason || "Unknown reason");

      if (!isManuallyClosed.current && reconnectAttempts.current < 5) {
        const delay = Math.min(3000 * 2 ** reconnectAttempts.current, 30000); // Exponential backoff, max 30s
        console.log(`🔄 Reconnecting in ${delay / 1000}s...`);
        reconnectTimeout.current = setTimeout(connectSocket, delay);
        reconnectAttempts.current += 1;
      } else {
        console.error("❌ Maximum WebSocket reconnection attempts reached.");
      }
    };

    socket.onerror = (error) => {
      console.error("❌ WebSocket error:", error);
      socket.close();
    };
  };
  const switchChannel = (newChannelId: string) => {
    const prevChannelId = store.getState().channel; // Get current channel from Redux
    if (prevChannelId === newChannelId) return; // Prevent unnecessary switching

    const socket = socketRef.current;
    if (socket && socket.readyState === WebSocket.OPEN) {
      console.log(`🔄 Switching from ${prevChannelId} to ${newChannelId}`);
      socket.send(
        JSON.stringify({
          event: "SWITCH_CHANNEL",
          prevChannelId,
          channelId: newChannelId,
        })
      );

      // Update Redux state
      store.dispatch(setChannel(newChannelId));
    } else {
      console.warn("⚠️ WebSocket not open, cannot switch channel.");
    }
  };
  useEffect(() => {
    connectSocket();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("🔄 Tab active, checking WebSocket connection...");
        connectSocket();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      isManuallyClosed.current = true; // Mark as manually closed
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const sendMessage = (message: Message) => {
    const socket = socketRef.current;
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.warn("⚠️ WebSocket not open, queuing message...");
      messageQueue.current.push(message);
      connectSocket();
    }
  };

  return { sendMessage, switchChannel };
};

export default WebSocketClient;
