import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCopy,
  faEdit,
  faTrash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const PasswordsTable = ({
  accounts,
  selectedService,
  handleEditPassword,
  handleDeleteEntry,
}: any) => {
  const [showPasswords, setShowPasswords] = useState<boolean[]>(
    Array(accounts.length).fill(false)
  );
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

  const handleTogglePasswordVisibility = (index: number) => {
    setShowPasswords((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  const handleCopyToClipboard = (password: string, index: number) => {
    navigator.clipboard.writeText(password);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 1500);
  };

  return (
    <div className="table-container">
      <table className="passwords-table">
        <thead>
          <tr>
            <th>Account</th>
            <th>Password</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account: any, index: any) => (
            <tr key={index}>
              <td>{account.accountName}</td>
              <td className="password-cell">
                {showPasswords[index] ? (
                  account.password
                ) : (
                  <span className="masked-password">••••••••</span>
                )}
                <button
                  onClick={() => handleTogglePasswordVisibility(index)}
                  className="icon-button"
                >
                  <FontAwesomeIcon
                    icon={showPasswords[index] ? faEyeSlash : faEye}
                  />
                </button>
              </td>
              <td className="buttons">
                <button
                  onClick={() => handleCopyToClipboard(account.password, index)}
                  className="icon-button"
                >
                  <FontAwesomeIcon icon={faCopy} />
                  {copiedIndex === index && (
                    <span className="copied-text">Copied!</span>
                  )}
                </button>
                <button
                  onClick={() => handleEditPassword(account)}
                  className="icon-button"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={() =>
                    handleDeleteEntry({
                      account: account.accountName,
                      service: selectedService,
                    })
                  }
                  className="icon-button delete-button"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default PasswordsTable;
