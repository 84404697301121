import { Archive, Delete, VolumeOff, VolumeUp } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  handleArchiveAll,
  handleDeleteAll,
  handleMute,
} from "../settings/Settings";
import "./Chat.scss";
import store from "../../redux";

interface ChatSidebarProps {
  currentChannel: string;
  handleChange: (event: { target: { value: string } }) => void;
  openConvos: { _id: string; channelId: string }[];
  handleCreateChannel: () => void;
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({
  currentChannel,
  handleChange,
  openConvos,
  handleCreateChannel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMute, setIsMute] = useState(false);

  useEffect(() => {
    // Subscribe to Redux store changes

    const unsubscribe = store.subscribe(() => {
      const state: any = store.getState();
      // Update local state from Redux state
      setIsMute(state.isMute);
    });

    // Cleanup subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array ensures this runs only once

  const handleSettingsClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false); // Ensure sidebar is always open on desktop
    }
  }, [isMobile]);

  const toggleSidebar = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarContent = (
    <div className="chat-sidebar">
      <Typography variant="h6" className="sidebar-title">
        Channels
      </Typography>
      <List style={{ height: "80%", overflow: "scroll", width: "95%" }}>
        {openConvos.map((convo) => (
          <ListItem
            key={convo._id}
            button
            selected={currentChannel === convo.channelId}
            onClick={() => {
              handleChange({ target: { value: convo.channelId } });
              if (isMobile) toggleSidebar(); // Auto-close on mobile
            }}
            className={
              currentChannel === convo.channelId ? "selected-channel" : ""
            }
            sx={{
              color: "white",
              "&.selected-channel": {
                backgroundColor: "#333",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
          >
            <ListItemText primary={convo.channelId} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <ListItem
        button
        onClick={() => {
          handleCreateChannel();
          if (isMobile) toggleSidebar();
        }}
        sx={{ color: "white", "&:hover": { backgroundColor: "#444" } }}
      >
        <ListItemText primary="Start a new convo" />
      </ListItem>
      <Divider />
      {/* Dark Mode Popover */}
      <ListItem
        button
        onClick={handleSettingsClick}
        sx={{ color: "white", "&:hover": { backgroundColor: "#444" } }}
      >
        <ListItemText primary="Settings" />
      </ListItem>{" "}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            padding: "10px",
            backgroundColor: "#222",
            color: "white",
          },
        }}
      >
        <MenuItem
          onClick={async () => {
            await handleMute(!isMute);
            handleClose();
          }}
          sx={{
            color: "white",

            "&:hover": { backgroundColor: "#444" },
          }}
        >
          {isMute ? (
            <VolumeUp fontSize="small" />
          ) : (
            <VolumeOff fontSize="small" />
          )}
          <Typography variant="body2" sx={{ ml: 1 }}>
            {isMute ? "Unmute Notifications" : "Mute Notifications"}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleDeleteAll();
            handleClose();
          }}
          sx={{ color: "white", "&:hover": { backgroundColor: "#444" } }}
        >
          <Delete fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Delete Chats
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleArchiveAll();
            handleClose();
          }}
          sx={{ color: "white", "&:hover": { backgroundColor: "#444" } }}
        >
          <Archive fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Archive Chats
          </Typography>
        </MenuItem>
      </Popover>{" "}
      <Divider />
    </div>
  );

  return (
    <>
      {/* Mobile Sidebar Toggle Button */}
      {isMobile && (
        <div
          style={{
            display: "flex",
            paddingBottom: "20px",
            width: "50px",
            alignItems: "flex-end",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {/* <IconButton onClick={toggleSidebar} className="sidebar-toggle"> */}
          <SettingsIcon
            fontSize="large"
            onClick={toggleSidebar}
            className="sidebar-toggle"
          />
          {/* </IconButton> */}
        </div>
      )}

      {/* Use Drawer on Mobile, Inline on Desktop */}
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={toggleSidebar}
          sx={{
            "& .MuiDrawer-paper": {
              width: 250,
              backgroundColor: "#222",
              color: "white",
            },
          }}
        >
          {sidebarContent}
        </Drawer>
      ) : (
        <div className="chat-sidebar-container">{sidebarContent}</div>
      )}
    </>
  );
};

export default ChatSidebar;
