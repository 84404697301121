import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { QRCodeCanvas } from "qrcode.react";
import api from "../../api";

const Enable2FA = () => {
  const [loading, setLoading] = useState(false);
  const [otpAuthUrl, setOtpAuthUrl] = useState<string | null>(null);
  const [secretKey, setSecretKey] = useState<string | null>(null);
  const [otpCode, setOtpCode] = useState("");
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);

  // Step 1: Request 2FA Setup
  const handleEnable2FA = async () => {
    setLoading(true);
    try {
      const response = await api.post("/auth/enable-2fa");
      if (response?.status === 200) {
        setOtpAuthUrl(response.data.otpAuthUrl); // Use otpAuthUrl instead of qrCodeUrl
        setSecretKey(response.data.secret);
      } else {
        toast.error(response.data?.error || "Unexpected response error.");
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error || "Failed to generate 2FA QR code.";
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Ensure loading stops no matter what
    }
  };

  // Step 2: Verify the OTP Code
  const handleVerify2FA = async () => {
    setLoading(true);
    try {
      const response = await api.post("/auth/verify-2fa", {
        twoFACode: otpCode,
        userId: JSON.parse(localStorage.getItem("user") || "")?.id,
      });
      if (response.status === 200) {
        setIs2FAEnabled(true);
        toast.success("2FA successfully enabled!");
      }
    } catch (error) {
      toast.error("Invalid 2FA code. Please try again.");
    }
    setLoading(false);
  };

  return (
    <Card
      sx={{ padding: 3, maxWidth: 400, margin: "auto", textAlign: "center" }}
    >
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {is2FAEnabled ? "2FA Enabled" : "Enable Two-Factor Authentication"}
      </Typography>

      {!otpAuthUrl && !is2FAEnabled && (
        <Button
          variant="contained"
          onClick={handleEnable2FA}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Generate QR Code"}
        </Button>
      )}

      {otpAuthUrl && (
        <>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Scan this QR code with Google Authenticator:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", marginY: 2 }}>
            <QRCodeCanvas value={otpAuthUrl} size={180} />{" "}
          </Box>
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            Or enter this key manually: <b>{secretKey}</b>
          </Typography>

          <TextField
            label="Enter 6-digit OTP"
            variant="outlined"
            fullWidth
            sx={{ marginTop: 2 }}
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />

          <Button
            variant="contained"
            color="success"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={handleVerify2FA}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Verify & Enable 2FA"}
          </Button>
        </>
      )}

      {is2FAEnabled && (
        <Typography variant="body1" sx={{ marginTop: 2, color: "green" }}>
          ✅ 2FA is now enabled on your account.
        </Typography>
      )}
    </Card>
  );
};

export default Enable2FA;
