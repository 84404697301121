import {
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Box,
  TextField,
  Divider,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api";
import store from "../../redux";
import { setMute } from "../../redux/ChatStore";
import "./settings.scss";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const SettingsPage = () => {
  const [settings, setSettings] = useState<any>({
    allowGuests: false,
    tokenExpiration: "2h",
    paCommands: false,
    showCommandsOutput: true,
    _id: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.get("/settings");
        setSettings(response.data);
      } catch (error) {
        toast.error("Failed to load site settings.");
      }
    };
    fetchSettings();
  }, []);

  const updateSettings = useCallback(async () => {
    if (!settings._id) return;
    try {
      await api.put(`/settings/${settings._id}`, settings);
      toast.success("Settings updated successfully!");
    } catch {
      toast.error("Failed to update site settings.");
    }
  }, [settings]);

  useEffect(() => {
    const timeout = setTimeout(updateSettings, 500);
    return () => clearTimeout(timeout);
  }, [settings, updateSettings]);

  const handleInputChange = (key: any) => (event: any) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setSettings((prev: any) => ({ ...prev, [key]: value }));
  };

  return (
    <Box
      sx={{
        // maxWidth: 600,
        mx: "auto",
        my: 4,
        p: 3,
        borderRadius: 2,
        // boxShadow: 3,
        // backgroundColor: "#212121",
        color: "white",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => navigate("/enable2FA")}
        sx={{ mb: 2 }}
      >
        Enable 2FA
      </Button>

      <Typography variant="h6">Site Settings</Typography>
      <TextField
        fullWidth
        label="Token Expiration (e.g., 2h)"
        variant="outlined"
        value={settings.tokenExpiration}
        onChange={handleInputChange("tokenExpiration")}
        sx={{ my: 2, color: "white", borderRadius: 1 }}
      />

      {["allowGuests", "paCommands", "showCommandsOutput"].map((key: any) => (
        <FormControlLabel
          key={key}
          control={
            <StyledSwitch
              checked={settings[key]}
              onChange={handleInputChange(key)}
            />
          }
          label={key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str: any) => str.toUpperCase())}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mb: 1,
          }}
        />
      ))}

      <Divider sx={{ my: 3 }} />
      <Typography variant="h6">Archive Options</Typography>
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleArchiveAll}
        >
          Archive All
        </Button>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={handleDeleteAll}
        >
          Delete All
        </Button>
      </Box>
    </Box>
  );
};

export const handleArchiveAll = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user") || "null");
    if (!user) return toast.error("User not found.");
    await api.post("/chat/archive/all", { userId: user.id });
    toast.success("All tasks archived!");
  } catch {
    toast.error("Failed to archive tasks.");
  }
};

export const handleDeleteAll = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user") || "null");
    if (!user) return toast.error("User not found.");
    await api.post("/chat/delete/all", { userId: user.id });
    toast.success("All tasks deleted!");
  } catch {
    toast.error("Failed to delete tasks.");
  }
};

export const handleMute = async (mute: any) => {
  try {
    await api.post("/settings/mute", { mute });
    store.dispatch(setMute(mute));
    toast.success(mute ? "Muted" : "Unmuted");
  } catch {
    toast.error("Failed to mute.");
  }
};

export default SettingsPage;
