// components/UniversalProfileEditor.js
import React, { useState, useEffect } from "react";
import "./UniversalProfileEditor.scss";
import api from "../../api";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
const UniversalProfileEditor = () => {
  const [profile, setProfile] = useState<any>({
    name: "",
    username: "",
    bio: "",
    email: "",
    pronouns: "",
    company: "",
    location: "",
    website: "",
    socials: {
      linkedin: "",
      github: "",
      twitter: "",
      socialProfile1: "",
      socialProfile2: "",
      socialProfile3: "",
      socialProfile4: "",
    },
    experience: [],
    education: [],
    skills: [],
  });

  // Temporary state for new experience, new education, new skill
  const [newExperience, setNewExperience] = useState({
    title: "",
    company: "",
    startDate: "",
    endDate: "",
    description: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const [newEducation, setNewEducation] = useState({
    school: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  const [canEdit, setCanEdit] = useState(false);

  const [newSkill, setNewSkill] = useState("");

  useEffect(() => {
    getProfile();
    setCanEdit(!!JSON.parse(localStorage.getItem("user") || "")?.isAdmin);
  }, []);

  const getProfile = async () => {
    const res = await api.post("linkedIn/me", {
      user: localStorage.getItem("user"),
    });
    let temp = {
      name: "",
      username: "",
      bio: "",
      email: "",
      company: "",
      location: "",
      website: "",
      socials: {
        linkedin: "",
        github: "",
        twitter: "",
        socialProfile1: "",
        socialProfile2: "",
        socialProfile3: "",
        socialProfile4: "",
      },
      experience: [],
      education: [],
      skills: [],
    };
    temp = { ...temp, ...res.data, ...res.data.profile };
    setProfile(temp);
  };

  // Generic change handler for top-level fields
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    // Handle checkbox
    if (type === "checkbox") {
      setProfile((prev: any) => ({ ...prev, [name]: checked }));
      return;
    }

    // Handle socials (nested object)
    if (name.startsWith("socials.")) {
      const key = name.split(".")[1];
      setProfile((prev: any) => ({
        ...prev,
        socials: { ...prev.socials, [key]: value },
      }));
      return;
    }

    // Everything else
    setProfile((prev: any) => ({ ...prev, [name]: value }));
  };

  const onSave = async (prev: any) => {
    const result = await api.post("/linkedIn/update", {
      ...prev,
      user: localStorage.getItem("user"),
    });
    setProfile(result.data);
    toast.success("Profile saved successfully");
  };
  // Experience form handlers
  const handleExperienceChange = (e: any) => {
    const { name, value } = e.target;
    setNewExperience((prev) => ({ ...prev, [name]: value }));
  };

  const addExperience = () => {
    if (!newExperience.title || !newExperience.company) return;
    setProfile((prev: any) => ({
      ...prev,
      experience: [...prev.experience, newExperience],
    }));
    setNewExperience({
      title: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
    });
  };

  const removeExperience = (index: any) => {
    setProfile((prev: any) => ({
      ...prev,
      experience: prev.experience.filter((_: any, i: any) => i !== index),
    }));
  };

  // Education form handlers
  const handleEducationChange = (e: any) => {
    const { name, value } = e.target;
    setNewEducation((prev) => ({ ...prev, [name]: value }));
  };

  const addEducation = () => {
    if (!newEducation.school) return;
    setProfile((prev: any) => ({
      ...prev,
      education: [...prev.education, newEducation],
    }));
    setNewEducation({
      school: "",
      degree: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
      description: "",
    });
  };

  const removeEducation = (index: any) => {
    setProfile((prev: any) => ({
      ...prev,
      education: prev.education.filter((_: any, i: any) => i !== index),
    }));
  };

  // Skills form handlers
  const addSkill = () => {
    if (!newSkill.trim()) return;
    setProfile((prev: any) => ({
      ...prev,
      skills: [...prev.skills, newSkill.trim()],
    }));
    setNewSkill("");
  };

  const removeSkill = (index: any) => {
    setProfile((prev: any) => ({
      ...prev,
      skills: prev.skills.filter((_: any, i: any) => i !== index),
    }));
  };

  // Submit the entire form
  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave(profile); // or call an API
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ padding: "30px 30px 0px 65px", cursor: "pointer" }}>
        {canEdit && (
          <EditIcon
            onClick={() => {
              setIsEditing(!isEditing);
              toast.info("Editing mode turned on", { autoClose: 3000 });
            }}
            color={isEditing ? "info" : "error"}
          ></EditIcon>
        )}
      </div>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="basic-info">
          <h2>Basic Info</h2>
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Username:</label>
            <input
              type="text"
              name="username"
              value={profile.username || profile.given_name}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label>Company:</label>
            <input
              type="text"
              name="company"
              value={profile.company}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Location:</label>
            <input
              type="text"
              name="location"
              value={profile.location || profile?.locale?.country}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Website:</label>
            <input
              type="text"
              name="website"
              value={profile.website}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Bio:</label>
            <textarea name="bio" value={profile.bio} onChange={handleChange} />
          </div>
        </div>

        <div className="experience">
          <h2>Experience</h2>
          {profile.experience.map((exp: any, index: any) => (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                margin: "0.5rem 0",
                padding: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <p>
                <strong>Title:</strong> {exp.title}
              </p>
              <p>
                <strong>Company:</strong> {exp.company}
              </p>
              <p>
                <strong>Start Date:</strong>{" "}
                {exp.startDate ? exp.startDate.toString().slice(0, 10) : ""}
              </p>
              <p>
                <strong>End Date:</strong>{" "}
                {exp.endDate ? exp.endDate.toString().slice(0, 10) : "Present"}
              </p>
              <p>
                <strong>Description:</strong> {exp.description}
              </p>
              {isEditing && (
                <button type="button" onClick={() => removeExperience(index)}>
                  Remove
                </button>
              )}
            </div>
          ))}
          {isEditing && (
            <>
              <div>
                <label>Title:</label>
                <input
                  type="text"
                  name="title"
                  value={newExperience.title}
                  onChange={handleExperienceChange}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>Company:</label>
                <input
                  type="text"
                  name="company"
                  value={newExperience.company}
                  onChange={handleExperienceChange}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>Start Date:</label>
                <input
                  type="date"
                  name="startDate"
                  value={newExperience.startDate}
                  onChange={handleExperienceChange}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>End Date:</label>
                <input
                  type="date"
                  name="endDate"
                  value={newExperience.endDate}
                  onChange={handleExperienceChange}
                />
              </div>
              <div>
                <label>Description:</label>
                <textarea
                  name="description"
                  value={newExperience.description}
                  onChange={handleExperienceChange}
                  disabled={!isEditing}
                />
              </div>
              <button type="button" onClick={addExperience}>
                Add Experience
              </button>
            </>
          )}
        </div>
        <div className="social-accounts">
          <h2>Social Accounts</h2>
          <div>
            <label>LinkedIn:</label>
            <input
              type="text"
              name="socials.linkedin"
              value={profile.socials.linkedin}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>GitHub:</label>
            <input
              type="text"
              name="socials.github"
              value={profile.socials.github}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Twitter:</label>
            <input
              type="text"
              name="socials.twitter"
              value={profile.socials.twitter}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Social profile 1:</label>
            <input
              type="text"
              name="socials.socialProfile1"
              value={profile.socials.socialProfile1}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Social profile 2:</label>
            <input
              type="text"
              name="socials.socialProfile2"
              value={profile.socials.socialProfile2}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Social profile 3:</label>
            <input
              type="text"
              name="socials.socialProfile3"
              value={profile.socials.socialProfile3}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label>Social profile 4:</label>
            <input
              type="text"
              name="socials.socialProfile4"
              value={profile.socials.socialProfile4}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
        </div>
        <div className="education">
          <h2>Education</h2>
          {profile.education.map((edu: any, index: any) => (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                margin: "0.5rem 0",
                padding: "0.5rem",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <p>
                <strong>School:</strong> {edu.school}
              </p>
              <p>
                <strong>Degree:</strong> {edu.degree}
              </p>
              <p>
                <strong>Field Of Study:</strong> {edu.fieldOfStudy}
              </p>
              <p>
                <strong>Start Date:</strong>{" "}
                {edu.startDate ? edu.startDate.toString().slice(0, 10) : ""}
              </p>
              <p>
                <strong>End Date:</strong>{" "}
                {edu.endDate ? edu.endDate.toString().slice(0, 10) : "Present"}
              </p>
              <p>
                <strong>Description:</strong> {edu.description}
              </p>
              {isEditing && (
                <button type="button" onClick={() => removeEducation(index)}>
                  Remove
                </button>
              )}
            </div>
          ))}
          {isEditing && (
            <>
              <div>
                <label>School:</label>
                <input
                  type="text"
                  name="school"
                  value={newEducation.school}
                  onChange={handleEducationChange}
                />
              </div>
              <div>
                <label>Degree:</label>
                <input
                  type="text"
                  name="degree"
                  value={newEducation.degree}
                  onChange={handleEducationChange}
                />
              </div>
              <div>
                <label>Field of Study:</label>
                <input
                  type="text"
                  name="fieldOfStudy"
                  value={newEducation.fieldOfStudy}
                  onChange={handleEducationChange}
                />
              </div>
              <div>
                <label>Start Date:</label>
                <input
                  type="date"
                  name="startDate"
                  value={newEducation.startDate}
                  onChange={handleEducationChange}
                />
              </div>
              <div>
                <label>End Date:</label>
                <input
                  type="date"
                  name="endDate"
                  value={newEducation.endDate}
                  onChange={handleEducationChange}
                />
              </div>
              <div>
                <label>Description:</label>
                <textarea
                  name="description"
                  value={newEducation.description}
                  onChange={handleEducationChange}
                />
              </div>
              <button type="button" onClick={addEducation}>
                Add Education
              </button>
            </>
          )}
        </div>
        <div className="skills">
          <h2>Skills</h2>
          <ul>
            {profile.skills.map((skill: any, index: any) => (
              <li key={index}>
                {skill}
                {isEditing && (
                  <button type="button" onClick={() => removeSkill(index)}>
                    Remove
                  </button>
                )}
              </li>
            ))}
          </ul>
          {isEditing && (
            <>
              <input
                type="text"
                placeholder="Add a skill"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
              />
              <button type="button" onClick={addSkill}>
                Add Skill
              </button>
            </>
          )}
        </div>
        {isEditing && (
          <button type="submit" style={{ marginTop: "1rem" }}>
            Save Profile
          </button>
        )}
      </form>
    </div>
  );
};

export default UniversalProfileEditor;
