import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import api from "../../api";
import store from "../../redux";
import { setIsConnected, setUserName } from "../../redux/ChatStore";
import videoSrc from "../../video/AdobeStock_789998454.mov";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#90caf9" },
    secondary: { main: "#f48fb1" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `body { background-color: #212121; color: #fff; }`,
    },
  },
});

const LandingPage = () => {
  const navigate = useNavigate();
  const [is2FARequired, setIs2FARequired] = useState(false);
  const [isSMSRequired, setIsSMSRequired] = useState(false);
  const [loginData, setLoginData] = useState<{ userId: string } | null>(null);
  const [twoFACode, setTwoFACode] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [isGuestsEnabled, setIsGuestsEnabled] = useState(false);

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    const initialState = store.getState();
    setIsGuestsEnabled(!!initialState.isAllowGuests);

    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      setIsGuestsEnabled(!!state.isAllowGuests);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await api.post("/auth/login", {
        identifier: data.get("identifier"),
        password: data.get("password"),
        rememberMe: data.get("remember"),
      });

      // Check if SMS verification is required
      if (response.data.requiresSms) {
        setIsSMSRequired(true);
        setLoginData({ userId: response.data.userId });
        return;
      }

      // Check for 2FA requirement
      if (response.data.requires2FA) {
        setIs2FARequired(true);
        setLoginData({ userId: response.data.userId });
        return;
      }

      handleLoginSuccess(response.data);
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
    }
  };

  const handle2FASubmit = async (e: any, twoFACode: string) => {
    e.preventDefault();

    if (!loginData) return;

    try {
      const response = await api.post("/auth/verify-2fa", {
        userId: loginData.userId,
        twoFACode,
      });
      if (response.status === 200) {
        handleLoginSuccess(response.data);
      } else {
        toast.error("Invalid 2FA code. Please try again.");
      }
    } catch (error) {
      toast.error("2FA verification failed.");
    }
  };

  const handleSmsSubmit = async () => {
    if (!loginData) return;

    try {
      const response = await api.post("/auth/verify-sms", {
        userId: loginData.userId,
        smsCode,
      });
      if (response.status === 200) {
        handleLoginSuccess(response.data);
      } else {
        toast.error("Invalid SMS code. Please try again.");
      }
    } catch (error) {
      toast.error("SMS verification failed.");
    }
  };

  const handleLoginSuccess = (data: any) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data.user));
    store.dispatch(setUserName(data.user.username || data.user.id));
    store.dispatch(setIsConnected(true));
    navigate("/chat");
  };

  const handleGuest = async (event: any) => {
    event.preventDefault();
    try {
      const response = await api.post("/auth/guest");
      if (response.status === 200) {
        handleLoginSuccess(response.data);
      } else {
        toast.error("Unable to create guest session. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while logging in as a guest.");
    }
  };

  const TwoFactorInput = ({ handle2FASubmit }: any) => {
    const [otp, setOtp] = useState(Array(6).fill(""));

    const handleOtpChange = (e: any, index: any) => {
      const { value } = e.target;
      // Allow only digits (or empty string)
      if (/^\d?$/.test(value)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        // Auto-focus next input when a digit is entered
        if (value && index < 5) {
          const nextInput = document.getElementById(`otp-${index + 1}`);
          if (nextInput) nextInput.focus();
        }
      }
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h6" textAlign="center">
          Enter the 2FA code from your Authenticator app.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {otp.map((digit, index) => (
            <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                id={`otp-${index}`}
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                sx={{ width: "3rem" }}
              />
              {index < otp.length - 1 && (
                <Typography sx={{ marginX: "0.5rem" }}>-</Typography>
              )}
            </Box>
          ))}
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={(e) => handle2FASubmit(e, otp.join(""))}
        >
          Verify
        </Button>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="landing-container" role="main">
        <video className="video-background" autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
        </video>
        <Card
          variant="outlined"
          sx={{
            maxWidth: "450px",
            padding: "2rem",
            margin: "auto",
            backgroundColor: "rgba(33, 33, 33, 0.7)",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography component="h1" variant="h4" sx={{ textAlign: "center" }}>
            {is2FARequired
              ? "Two-Factor Authentication"
              : isSMSRequired
              ? "SMS Verification"
              : "Sign in"}
          </Typography>

          {!is2FARequired && !isSMSRequired ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <FormControl>
                <FormLabel htmlFor="identifier">Username or Email</FormLabel>
                <TextField
                  id="identifier"
                  name="identifier"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox name="remember" color="primary" />}
                label="Remember me"
              />
              <Button type="submit" fullWidth variant="contained">
                Sign in
              </Button>
            </Box>
          ) : is2FARequired ? (
            <TwoFactorInput handle2FASubmit={handle2FASubmit} />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="h6" textAlign="center">
                Enter the SMS code sent to your phone.
              </Typography>
              <TextField
                label="SMS Code"
                variant="outlined"
                fullWidth
                value={smsCode}
                onChange={(e) => setSmsCode(e.target.value)}
              />
              <Button fullWidth variant="contained" onClick={handleSmsSubmit}>
                Verify
              </Button>
            </Box>
          )}

          <Divider>or</Divider>

          {isGuestsEnabled && (
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={handleGuest}
            >
              Continue as Guest
            </Button>
          )}
        </Card>
      </div>
    </ThemeProvider>
  );
};

export default LandingPage;
