import { Tooltip } from "@material-ui/core";
import { ContentCopy, Mic } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import api from "../../api";
import store from "../../redux";
import WebSocketClient from "../../sockets/socket";
import "./Chat.scss";
import ChatSidebar from "./chatSideBar";
import { setRefreshed } from "../../redux/ChatStore";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [currentChannel, setCurrentChannel] = useState("PublicChannel");
  const [mode, setMode] = useState<"chat" | "exec" | "message">(() => {
    return (
      (localStorage.getItem("chatMode") as "chat" | "exec" | "message") ||
      "message"
    );
  }); // Persistent mode
  const { sendMessage, switchChannel } = WebSocketClient();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);
  const [openConvos, setOpenConvos] = useState<
    { channelId: string; _id: string }[]
  >([]);

  useEffect(() => {
    switchChannel(currentChannel);
  }, [currentChannel]);

  // Effect to sync transcript with input
  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);

  // Effect to persist mode change in localStorage
  useEffect(() => {
    localStorage.setItem("chatMode", mode);
  }, [mode]);

  useEffect(() => {
    // pull messages from db
  }, []);

  // Effect to watch Redux store changes (mute state)
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      if (state.refreshed) {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        if (!user.id) return;

        api
          .post("/chat/channels", { user })
          .then((res) => {
            setOpenConvos(res.data.channels);
            store.dispatch(setRefreshed(false));
          })
          .catch((err) => console.error(err));
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Handle Key Events
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      send();
    }
  };

  // Detect command and switch modes dynamically while keeping mode persistent
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    setMessage(input);
  };

  const send = () => {
    if (!message.trim()) return;

    // Handle mode-switching commands
    if (message.toLowerCase() === "mode pa") {
      setMode("chat");
      setMessage(""); // Clear input after switching
      return;
    }
    if (message.toLowerCase() === "mode exec") {
      setMode("exec");
      setMessage(""); // Clear input after switching
      return;
    }
    if (message.toLowerCase().includes("mode ")) {
      setMode("message");
      setMessage(""); // Clear input after switching
      return;
    }

    if (message) {
      let messageToBack = message;
      if (mode === "chat") {
        messageToBack = `/pa: ${message}`;
      }
      if (mode === "exec") {
        messageToBack = `/exec: ${message}`;
      }
      sendMessage({
        channelId: currentChannel,
        sender: store.getState().username,
        content: messageToBack,
        event: "SEND_MESSAGE",
      });

      setMessage("");
      resetTranscript();
    } else {
      toast.error("Message is empty");
    }
  };

  return (
    <div className="chat-container">
      <ChatSidebar
        currentChannel={currentChannel}
        handleChange={(e: any) => setCurrentChannel(e.target.value)}
        openConvos={openConvos}
        handleCreateChannel={() => {
          const user = JSON.parse(localStorage.getItem("user") || "{}");
          if (!user.id) return;
          const newChannel = `channel-${Date.now()}`;
          api
            .post("/chat/channel/add", {
              userId: user.id,
              channelId: newChannel,
            })
            .then((res) => {
              setCurrentChannel(newChannel);
            })
            .catch((err) => console.error(err));
        }}
      />
      <div ref={scrollableDivRef} className="chat-wrapper">
        <MessageDisplay />
        <div className="input-wrapper">
          <div className="input-container">
            <TextField
              autoFocus
              fullWidth
              placeholder={`Message (${mode.toUpperCase()} MODE)`}
              value={message}
              autoComplete="off"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                style: { color: "white" },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() =>
                        listening
                          ? SpeechRecognition.stopListening()
                          : SpeechRecognition.startListening()
                      }
                    >
                      <Mic color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={send}>
                      <SendIcon color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageComponent = ({ message }: any) => {
  const formattedContent = message?.content?.replace(/\r/g, "<br/>");
  const handleCopy = () => {
    navigator.clipboard.writeText(message.content);
    toast.success("Message copied to clipboard!");
  };

  return (
    <div className="message-container">
      <span
        className="message"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      ></span>
      <Tooltip title="Copy">
        <IconButton className="copy-button" onClick={handleCopy}>
          <ContentCopy style={{ fontSize: "0.5em" }} />
        </IconButton>
      </Tooltip>
      <small className="timestamp">{moment().fromNow(message.timestamp)}</small>
    </div>
  );
};

const splitTextIntoChunks = (text: string = "", maxLength = 100) => {
  if (!text.length) return;
  const sentences = text.match(/[^.!?]+[.!?]*/g) || []; // Split by sentences
  const chunks: string[] = [];
  let currentChunk = "";

  sentences.forEach((sentence) => {
    if ((currentChunk + sentence).length <= maxLength) {
      currentChunk += sentence;
    } else {
      chunks.push(currentChunk);
      currentChunk = sentence;
    }
  });

  if (currentChunk) chunks.push(currentChunk);
  return chunks;
};

let voices: SpeechSynthesisVoice[] = [];
const speechSynthesisInitialized = new Promise<void>((resolve) => {
  // Ensure voices are loaded
  const populateVoices = () => {
    voices = window.speechSynthesis.getVoices();
    if (voices.length) {
      resolve();
    }
  };

  // Listen for voices changed event
  window.speechSynthesis.onvoiceschanged = populateVoices;
  populateVoices(); // Initial population attempt
});

const speakText = async (text: string, mute: boolean) => {
  if (mute) return; // Stop processing if muted
  console.time("speaking");

  if ("speechSynthesis" in window) {
    await speechSynthesisInitialized; // Wait until voices are loaded
    const chunks = splitTextIntoChunks(text) || [];

    for (const chunk of chunks) {
      if (mute) break; // Stop processing further if muted

      const utterance = new SpeechSynthesisUtterance(chunk);
      utterance.lang = "en-US";
      window.speechSynthesis.speak(utterance);

      // Wait for each chunk to finish before moving to the next one
      await new Promise<void>((resolve) => {
        utterance.onend = () => resolve();
      });
    }
    cancelSpeech();

    console.timeEnd("speaking");
  } else {
    toast("Text-to-Speech not supported in this browser.");
  }
};

const cancelSpeech = () => {
  if ("speechSynthesis" in window) {
    window.speechSynthesis.cancel();
  }
};

const MessageDisplay = () => {
  const [mute, setMute] = useState(true);
  const messageLog = useSelector((state: any) => state.messages); // Message history
  const username = useSelector((state: any) => state.username); // Current user's username
  const chatContainerRef = useRef<any>(null);

  useEffect(() => {
    const lastMessage = messageLog?.[messageLog?.length - 1];
    const lastSenderName = lastMessage?.sender;

    // Speak the message only if:
    // 1. It's not muted
    // 2. The sender is not the current user
    if ((!mute && lastSenderName !== username) || lastSenderName !== "guest") {
      speakText(lastMessage?.content, mute);
    }
    if (mute) {
      cancelSpeech(); // Stop ongoing speech synthesis when muted
    }
  }, [messageLog, mute, username]);

  useEffect(() => {
    // Subscribe to Redux store changes
    const unsubscribe = store.subscribe(() => {
      const state: any = store.getState();

      if (state.isMute) {
        cancelSpeech(); // Stop ongoing speech synthesis when muted
      }

      setMute(state.isMute); // Update local mute state
    });
    // Cleanup subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []);
  // 1) Create a ref to the chat container

  // 2) When messageLog changes, scroll to the bottom
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messageLog]);

  return (
    <div ref={chatContainerRef} className="display-chat">
      <div className="message-list">
        {messageLog?.length > 0 &&
          messageLog?.map((message: any) => (
            <div key={message.id} className="message-item">
              <span>{message?.sender ?? "Personal Assistant"}</span>
              <MessageComponent message={message} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Chat;
